<template>
  <div class="map-container">
    <div id="map"></div>
  </div>
</template>

<script>
import { Loader } from "@googlemaps/js-api-loader";
import greenIcon from "../../assets/truck-pin-green.png";
import darkBlueIcon from "../../assets/truck-pin-dark-blue.png";
import blueIcon from "../../assets/truck-pin-blue.png";
import redIcon from "../../assets/truck-pin-red.png";
import axios from "axios";
import { mapGetters } from "vuex";
import jsCookie from "js-cookie";

export default {
  props: {
    showedItemId: null,
    showDirectionStatus: null,
    showDetail: null,
    idList: null,
  },

  computed: {
    ...mapGetters({
      driverInfo: "operation/driverInfo",
      intervalTime: "home/intervalTime",
    }),
  },

  data() {
    return {
      currentMarker: null,
      map: null,
      markers: [],
      directions: [],
      icon: "",
      trafficLayer: null,
      isShowTraffic: false,
      showTrafficBtn: null,
      directionsService: {},
      directionsRenderer: {},
      currentFeatures: [],
      historyDrive: [],
      workingDate:
        String(new Date().getFullYear()) +
        "/" +
        String(new Date().getMonth() + 1).padStart(2, "0") +
        "/" +
        String(new Date().getDate()).padStart(2, "0"),
      mapIntervalId: null,
      interval: jsCookie.get("intervalTime") || 2,
    };
  },

  mounted() {
    setTimeout(() => {
      this.getData();
    }, 2000);
    this.initMap();
    this.showDirectionStatus = false;
    this.showDetail = false;
    this.setMapMarker();
  },

  watch: {
    currentFeatures: {
      handler() {
        if (this.currentFeatures) {
          this.updateMarkers();
        }
      },
      deep: true,
    },

    historyDrive: {
      handler() {
        if (this.historyDrive) {
          this.updateDirection();
        }
      },
      deep: true,
    },

    showDirectionStatus: {
      handler() {
        this.historyDrive.map((history) => {
          if (this.historyDrive.userId === this.showedItemId) {
            history.isShowDirection = !history.isShowDirection;
          }
          if (this.showedItemId === "") {
            history.isShowDirection = false;
          }
        });
      },
    },

    showDetail: {
      handler() {
        this.currentFeatures.map((feature) => {
          if (feature.userId === this.showedItemId) {
            feature.isShowDetail = !feature.isShowDetail;
          }
          if (this.showedItemId === "") {
            feature.isShowDetail = false;
          }
        });
      },
    },

    intervalTime: {
      handler(newValue) {
        this.interval = newValue;
        if (this.interval === 0) {
          clearInterval(this.mapIntervalId);
        } else {
          clearInterval(this.mapIntervalId);
          this.mapIntervalId = null;
          this.getData();
        }
      },
    },
  },
  methods: {
    //get data display on map BA-0011
    getData() {
      this.mapIntervalId = setInterval(
        (() => {
          this.callApi();
          return this.callApi;
        })(),
        this.interval * 60000
      );
    },

    callApi() {
      if (window.location.pathname === "/" && this.interval != 0) {
        let params = {
          date: this.workingDate,
          users: `[${this.idList}]`,
          version: "0",
        };
        let that = this;
        axios.defaults.headers.common = {
          "Content-Type": "application/json",
        };
        axios
          .get(that.$store.state.blockChainUrl + "drives/location", {
            params: params,
          })
          .then(function (response) {
            let data = JSON.parse(response.data);

            // get all user id value from response data
            let allUserId = data.map((x) => x.userId);

            // remove duplicate user id
            let uniqueArr = [...new Set(allUserId)];

            let subData = [];

            //get latest update data following each user id
            uniqueArr.forEach((id) => {
              let allUserData = data.filter((item) => {
                return item.userId === id;
              });

              // get data with max date time
              const maxDatetime = allUserData.reduce((p, c) => (p.dateTime > c.dateTime ? p : c));

              subData.push(maxDatetime);
            });

            let finalData = subData.map((x) => {
              return { ...x, isShowDetail: false, isShowDirection: false };
            });

            that.currentFeatures = [...finalData];
          })
          .catch(() => {});
      } else {
        clearInterval(this.mapIntervalId);
      }
    },

    getDriverHistory(id, startDate) {
      let params = {
        attendancesStartDatetime: startDate,
        userId: id,
        version: "0",
      };

      let that = this;
      axios.defaults.headers.common = {
        "Content-Type": "application/json",
      };
      axios
        .get(this.$store.state.blockChainUrl + "drives", { params: params })
        .then(function (response) {
          let data = JSON.parse(response.data);
          let finalData = data.map((x) => {
            return { ...x, isShowDirection: true };
          });

          that.historyDrive = [...finalData];
          that.historyDrive.userId = id;
        })
        .catch(() => {});
    },

    //remove marker
    removerMarkers() {
      this.markers.forEach((marker) => {
        marker?.setMap(null);
      });
    },

    //setting map
    initMap() {
      const loader = new Loader({
        apiKey: this.$store.state.googleApiKey,
        version: "places",
        region: "JP",
        language: "ja",
      });
      loader.load().then(() => {
        this.map = new window.google.maps.Map(document.getElementById("map"), {
          // display street
          zoom: 15,
        });

        //create traffic layer
        this.trafficLayer = new window.google.maps.TrafficLayer();

        //add button show traffic layer into the map
        this.map.controls[window.google.maps.ControlPosition.TOP_LEFT].push(this.showTraffic());
      });
    },

    //show traffic layer button
    showTraffic() {
      this.showTrafficBtn = document.createElement("button");
      this.showTrafficBtn.className = "traffic-button";
      this.showTrafficBtn.style.boxShadow = "0px 4px 4px rgba(0, 0, 0, 0.25)";
      this.showTrafficBtn.style.borderRadius = "15px";
      this.showTrafficBtn.style.border = "none";
      this.showTrafficBtn.style.width = "150px";
      this.showTrafficBtn.style.height = "30px";
      this.showTrafficBtn.style.marginTop = "17px";
      this.showTrafficBtn.style.color = "#ffffff";
      this.showTrafficBtn.style.fontWeight = "bold";
      this.showTrafficBtn.style.fontSize = "15px";
      this.showTrafficBtn.style.fontFamily = "Roboto";
      this.showTrafficBtn.innerHTML = "渋滞情報表示オン";
      this.showTrafficBtn.style.background = "#FF0000";

      //handle when click button
      this.showTrafficBtn.onclick = this.changeTraffic;

      return this.showTrafficBtn;
    },

    changeTraffic() {
      this.isShowTraffic = !this.isShowTraffic;
      if (this.isShowTraffic) {
        //setting change traffic button
        this.showTrafficBtn.innerHTML = "渋滞情報表示オフ";
        this.showTrafficBtn.style.background = "#4E92F8";

        //init traffic layer to map
        this.trafficLayer.setMap(this.map);
      } else {
        this.showTrafficBtn.innerHTML = "渋滞情報表示オン";
        this.showTrafficBtn.style.background = "#FF0000";

        //remove traffic layer
        this.trafficLayer.setMap(null);
      }
    },

    //create and setting marker
    createMarker(feature) {
      //create a direction for each marker
      if (!this.directionsRenderer[feature.userId]) {
        this.directionsService[feature.userId] = new window.google.maps.DirectionsService();
        this.directionsRenderer[feature.userId] = new window.google.maps.DirectionsRenderer();
      }

      // create popup
      const index = this.driverInfo?.data.findIndex((x) => x.user_id === feature.userId);

      if (
        this.driverInfo?.data[index].status_name !== "更新なし" &&
        this.driverInfo?.data[index].status_name !== "退勤"
      ) {
        const contentString =
          '<div id="content">' + this.driverInfo?.data[index].first_name + "</div>";
        const infoWindow = new window.google.maps.InfoWindow({
          content: contentString,
          pixelOffset: new window.google.maps.Size(0, 12), //position compare with marker
        });

        // marker image
        switch (this.driverInfo?.data[index].status_name) {
          case "移動中":
            this.icon = {
              url: blueIcon,
            };
            break;
          case "休憩中":
          case "仮眠中":
            this.icon = {
              url: greenIcon, // image url
            };
            break;
          case "待機中":
            this.icon = {
              url: redIcon, // image url
            };
            break;
          default:
            this.icon = {
              url: darkBlueIcon, // image url
            };
        }

        var zIndex = -1;
        //change icon size when click driver name
        if (feature.isShowDetail) {
          this.icon.scaledSize = new window.google.maps.Size(75, 80);
          zIndex = 999;
          if (feature.userId !== this.showedItemId) {
            zIndex = zIndex - 100;
          }
        } else {
          this.icon.scaledSize = new window.google.maps.Size(50, 55);
        }

        if (feature.userId === this.showedItemId) {
          this.map.setZoom(15);

          //set marker to center when click driver name
          this.setMarkerCenter({
            lat: feature.latitude,
            lng: feature.longitude,
          });
        }

        var marker = this.createMarkerDetail(feature, this.icon, this.map, infoWindow, zIndex);

        marker.addListener("click", () => {
          setTimeout(() => {
            const payload = {
              item: feature,
              showDetail: !feature.isShowDetail,
            };
            this.$store.dispatch("operation/showDriverDetail", payload);
          }, 100);

          //open data tab when click marker
          this.$emit("openDataTab");
        });
        return marker;
      }
    },

    //setting data marker display
    updateMarkers() {
      this.removerMarkers();
      this.markers = this.currentFeatures.map((feature) => {
        return this.createMarker(feature);
      });
    },

    setMapMarker() {
      //set map to display all marker
      if (this.map) {
        //set map to display Japan
        this.map?.fitBounds({
          west: 129.408463169,
          east: 145.543137242,
          north: 31.0295791692,
          south: 45.5514834662,
        });
        this.map?.panTo({
          lat: 38.65713312716425,
          lng: 137.4758002055,
        });
        this.map?.setZoom(6);
      } else {
        setTimeout(() => {
          this.setMapMarker();
        }, 500);
      }
    },

    createMarkerDetail(feature, icon, map, infoWindow, zIndex) {
      let marker = new window.google.maps.Marker({
        position: {
          lat: feature.latitude,
          lng: feature.longitude,
        },
        icon: icon,
        map: map,
        zIndex: zIndex,
      });
      //init popup to map
      infoWindow.open({
        anchor: marker,
        map: map,
        shouldFocus: false,
      });
      infoWindow.setZIndex(zIndex);

      return marker;
    },

    //setting marker to center when click driver name or click on marker
    setMarkerCenter(position) {
      // after the center of the map has changed, pan back to the marker.
      setTimeout(() => {
        this.map.setCenter(position);
      }, 300);
    },

    //setting data direction display
    createDirection(history) {
      let showDirection = history.some((x) => x.isShowDirection);
      if (showDirection) {
        var lngs = history.map(function (station) {
          return station.longitude;
        });
        var lats = history.map(function (station) {
          return station.latitude;
        });

        this.map.fitBounds({
          west: Math.min.apply(null, lngs),
          east: Math.max.apply(null, lngs),
          north: Math.min.apply(null, lats),
          south: Math.max.apply(null, lats),
        });

        let directionIcon = {
          path: "M2,0A2,2,0,1,1,0,2,2,2,0,0,1,2,0Z", //circle
          fillColor: "red",
          strokeWeight: 0,
          rotation: 0,
          scale: 4,
          fillOpacity: 0.6,
        };
        for (let i = 0; i < history.length; i++) {
          if (!window.gMarker) window.gMarker = [];
          window.gMarker.push(
            new window.google.maps.Marker({
              position: {
                lat: history[i].latitude,
                lng: history[i].longitude,
              },
              map: this.map,
              icon: directionIcon,
            })
          );
        }
      } else {
        if (window.gMarker) {
          window.gMarker.forEach((x) => x.setMap(null));
        }
      }
    },

    updateDirection() {
      return this.createDirection(this.historyDrive);
    },
  },
};
</script>

<style>
.map-container {
  position: relative;
  width: 100%;
  height: 90vh;
}
#map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  border: 1px solid #ccc;
}
.gm-style .gm-style-iw-t::after {
  width: 0px;
}
.gm-ui-hover-effect {
  display: none !important;
}
.gm-style .gm-style-iw {
  font-weight: 400;
  font-size: 14px;
  overflow: hidden;
  color: #000;
  text-align: center;
  min-width: 60px !important;
  padding: 0;
}
.gm-style-iw-d {
  padding-top: 6px;
  padding-bottom: 6px;
  overflow: hidden !important;
}
.gm-style-mtc,
.gm-control-active,
.gm-svpc,
.gm-style-cc,
[alt|="Google"] {
  display: none;
}
.gm-style .gm-style-iw-c {
  box-shadow: none;
  border: 0.5px solid;
}
</style>
